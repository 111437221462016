import {worldlineFunctions} from './chat-integration-function';
import {navigatorLang} from './chat-integration-function';

class EngagementStrategy {

    constructor(rawObject) {
        this.uuid = rawObject.uuid;
        this.name = rawObject.name;
        this.description = rawObject.description;
        this.minimized = rawObject.minimized;
        this.rules = rawObject.rules.map(o => new EngagementRule(o));
        this.allowAuthContacts = rawObject.allowAuthContacts;
        this.condition = rawObject.condition;
        this.match = false;
        this.message = rawObject.message;
        this.triggering = rawObject.triggering;
        this.triggeringCount = rawObject.triggeringCount;
    }
}

class EngagementRule {

    constructor(rawObject) {
        this.uuid = rawObject.uuid;
        this.type = rawObject.type;
        this.name = rawObject.name;
        this.operator = rawObject.operator;
        this.value = rawObject.value;
        this.match = rawObject.match;
        this.actualValue = rawObject.actualValue;
        this.order = rawObject.order;
        this.timeUnit = rawObject.timeUnit;
    }
}

export class EngagementService {
    constructor(wlContactObject, openChatFn) {
        this.url = wlContactObject.chatServerUrl + '/api/engagement-strategy/';
        this.needsAuthCheck = false;

        this.wlContactObject = wlContactObject;
        this.domainUuid = wlContactObject.domainUuid;
        this.visitorToken = null;
        //this.userAuthenticationStatus = null;
        this.shouldCheckVisitorToken = false;
        this.setVisitorToken(this.wlContactObject.visitorToken);
        this.openChatFn = openChatFn;
        this.checkRulesTimer = null;
        this.engagementRuleStatusUrl = wlContactObject.chatServerUrl + '/api/engagement-strategy/rules-status/';
        this.checkIsConnectedUrl = wlContactObject.chatServerUrl + '/api/authentication/isConnected/';

        // this.authService = {
        //     isCheckOngoing: false,
        //     check: wlContactObject.config.authServiceCheckFn
        // };

        worldlineFunctions.startStatsProcess();
        //Manage language by parameter
        if (wlContactObject.language && wlContactObject.language !== "") {
            this.lang = wlContactObject.language;
        } else {
            this.lang = navigatorLang;
        }

        if (wlContactObject.identificationActivated) {
            let chatCustomerId = sessionStorage.getItem('CHAT_INTEGRATION:chatCustomerId');
            if (chatCustomerId) {
                let xhr = new XMLHttpRequest();
                xhr.open('GET', this.checkIsConnectedUrl + chatCustomerId);
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        // customer is already connect and retrieve the engage strategy isd
                        this.openChatFn(false, true, xhr.responseText);
                    }
                    else {
                        if (wlContactObject.chatFlowUri) {
                            this.openChatFn(false);
                        } else {
                            this.getStrategies();
                        }
                    }
                };
                xhr.send();
            } else {
                if (wlContactObject.chatFlowUri) {
                    this.openChatFn(false);
                } else {
                    this.getStrategies();
                }
            }
        } else {
            if (wlContactObject.chatFlowUri) {
                this.openChatFn(false);
            } else {
                this.getStrategies();
            }
        }
    }

    setVisitorToken(token) {
        if (token !== this.visitorToken) {
            this.visitorToken = token;
            this.userAuthenticationStatus = null;
            this.shouldCheckVisitorToken = !!this.visitorToken;
        }
    }

    getStrategies() {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', this.url + this.domainUuid);
        xhr.onload = () => {
            if (xhr.status === 200) {
                this.onInitSuccedeed(xhr.responseText);
            } else {
                this.onInitFailed(xhr.status);
            }
        };
        xhr.send();
    }

    onInitSuccedeed(response) {
        let parseResponse = JSON.parse(response);
        if (parseResponse.length > 0) {
            let engagementStrategies = parseResponse.map(o => new EngagementStrategy(o));
            if (engagementStrategies) {
                this.startCheckingRulesStatus(engagementStrategies);
            }
        }
    }

    onInitFailed(status) {
        console.log(status);
    }

    startCheckingRulesStatus(engagementStrategies) {
        engagementStrategies = this.filterWithTriggeringCount(engagementStrategies);
        if (engagementStrategies.length > 0) {
            this.needsAuthCheck = engagementStrategies.some(strat => strat.allowAuthContacts);
            this.checkRulesTimer = setInterval(() => {
                // if (this.needsAuthCheck && this.shouldCheckVisitorToken) {
                //     this.doAuthCheck();
                // }
                const engagementStrategiesMatch = this.parseEngagementRule(engagementStrategies);
                if (engagementStrategiesMatch.length > 0) {
                    this.sendRulesStatus(engagementStrategiesMatch);
                }
            }, this.wlContactObject.timeCheckRuleSeconds * 1000);
        }
    }

    // doAuthCheck() {
    //     if (!this.authService.isCheckOngoing) {
    //         this.authService.isCheckOngoing = true;
    //         this.authService.check(this.visitorToken)
    //             .then((authResponse) => {
    //                 this.userAuthenticationStatus = authResponse;
    //             })
    //             .catch(() => {
    //                 this.userAuthenticationStatus = { authenticationState: 'FAILED' };
    //             })
    //             .finally(() => {
    //                 this.needsAuthCheck = false;
    //                 this.authService.isCheckOngoing = false;
    //             });
    //     }
    // }

    filterWithTriggeringCount(engagementStrategies) {
        // check triggering engagementStrategies
        let triggering = worldlineFunctions.getCookieTriggering();
        return engagementStrategies.filter(engagementStrategy => {
            let strategyUuid = engagementStrategy.uuid;
            if (engagementStrategy.triggering === 'NO' && triggering && triggering[strategyUuid] && triggering[strategyUuid] >= 1) {
                return false;
            } else if (engagementStrategy.triggering === 'CUSTOMIZED' && triggering && triggering[strategyUuid] && triggering[strategyUuid] >= engagementStrategy.triggeringCount) {
                return false;
            }
            return true;
        });
    }

    parseEngagementRule(engagementStrategies) {
        return engagementStrategies.filter(strategy => this.checkStrategyRulesMatch(strategy));
    }

    checkStrategyRulesMatch(strategy) {
        const rulesMap = this.buildRulesMap(strategy);
        let condition = strategy.condition;
        rulesMap.forEach((value, key) => {
            condition = condition.replace("$" + key, '(' + value + ')')
        });
        condition = condition.replace(/ AND /gi, " && ").replace(/ OR /gi, " || ");
        const rulesMatch = condition ? eval(condition) : true;
        const isVisitorAuthenticated = this.userAuthenticationStatus && this.userAuthenticationStatus.authenticationState !== 'FAILED';
        const authRuleMatch = !strategy.allowAuthContacts || isVisitorAuthenticated;
        return rulesMatch && authRuleMatch;
    }

    buildRulesMap(strategy) {
        const rulesMap = new Map();
        strategy.rules.forEach(rule => {
            if (rule.type !== 'METRIC') {
                let value = worldlineFunctions[rule.name.trim()]();
                let valueToCompare = rule.value;
                if (rule.type === 'TIME') {
                    value = Date.now() - parseFloat(value);
                    valueToCompare = parseFloat(valueToCompare) * 60 * 1000;
                }
                if (worldlineFunctions[rule.operator.trim()](value, valueToCompare)) {
                    rule.match = true;
                }
                rule.actualValue = value;
            }

            if (rulesMap.has(rule.order)) {
                rulesMap.set(rule.order, rulesMap.get(rule.order) + " OR " + rule.match);
            } else {
                rulesMap.set(rule.order, rule.match);
            }
        });
        return rulesMap;
    }

    sendRulesStatus(matches) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', this.engagementRuleStatusUrl + worldlineFunctions.format(this.lang).toLowerCase());
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onload = () => {
            if (xhr.status === 200) {
                this.onReceiveRulesStatusResponse(matches, xhr.responseText);
            }
            else {
                this.onInitFailed(xhr.status);
            }
        };
        xhr.send(JSON.stringify(matches));
    }

    onReceiveRulesStatusResponse(matches, responseText) {
        // OPEN CHAT
        if (responseText) {
            let minimized = false;
            let message = null;
            const response = JSON.parse(responseText);
            matches.forEach(engagementStrategy => {
                if (engagementStrategy.uuid === response.uuid) {
                    minimized = engagementStrategy.minimized;
                    message = response.message;
                    // BLE : To be discussed : why are we increasing the triggerring count only if in INFINITE  mode ??
                    if (engagementStrategy.triggering && engagementStrategy.triggering !== 'INFINITE') {
                        worldlineFunctions.incrementTriggering(engagementStrategy.uuid);
                    }
                }
            });
            this.openChatFn(minimized, false, response.uuid, message, this.userAuthenticationStatus, response.name);
            clearInterval(this.checkRulesTimer);
        }
    }
}
