export const navigatorLang = window.navigator.userLanguage || window.navigator.language;
let currentLastVisitValue;
export const worldlineFunctions = {

     getCookie: function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },

     setCookie: function(name, value) {
        document.cookie = name + "=" + value
    },

     incrementCookie: function(cookieName) {
        let number = worldlineFunctions.getCookie(cookieName);
        if (number === null) {
            worldlineFunctions.setCookie(cookieName, 1);
        } else {
            worldlineFunctions.setCookie(cookieName, parseInt(number) + 1);
        }
    },

    incrementTriggering: function(name) {
        let triggering = worldlineFunctions.getCookieTriggering();
        if (triggering && triggering[name]) {
            triggering[name] = triggering[name] + 1;
        } else {
            triggering[name] = 1;
        }
        worldlineFunctions.setCookie('_triggering', JSON.stringify(triggering));
    },

    getCookieTriggering: function() {
        let cookie = worldlineFunctions.getCookie('_triggering');
        return JSON.parse(cookie) || {};
    },

    /******** OPERATOR *******/
    contains: function(string, substring) {
        return string.indexOf(substring) !== -1;
    },

    equal: function(string, substring) {
        return string.toString().toLowerCase() === substring.toString().toLowerCase();
    },

    begins: function(string, substring) {
        return string.toString().startsWith(substring);
    },

    ends: function(string, substring) {
        return string.toString().endsWith(substring);
    },

    regex(string, regex) {
        let reg = new RegExp(regex, "g");
        if (string.match(reg)) {
            if (string.match(reg).length > 0) {
                return true;
            }
        }
        return false;
    },

    greater: function(actualValue, valueToCompare) {
        return parseFloat(actualValue) > parseFloat(valueToCompare);
    },

    greaterEqual: function(actualValue, valueToCompare) {
        return parseFloat(actualValue) >= parseFloat(valueToCompare);
    },

    less: function(actualValue, valueToCompare) {
        return parseFloat(actualValue) < parseFloat(valueToCompare);
    },

    lessEqual: function(actualValue, valueToCompare) {
        return parseFloat(actualValue) <= parseFloat(valueToCompare);
    },

    /*************  UPDATE FONCTIONS TO SET GET VALUES *************/
     startStatsProcess: function() {
        let session = sessionStorage.getItem('session');
        if (!session) {
            worldlineFunctions.incrementNumberOfVisit();
            worldlineFunctions.setTimeOnTheWebSite();
            sessionStorage.setItem('session', 'sessionNumber');
        }
        currentLastVisitValue = parseFloat(worldlineFunctions.getCookie("_lastVisit"));
        this.setLastVisit();

        worldlineFunctions.incrementNumberOfPageView();
        worldlineFunctions.setTimeOnCurrentPage();
    },

     pageUrl: function() {
        return window.location.href;
    },

    prevPageUrl: function() {
         return document.referrer;
    },

    language: function() {
         return this.format(navigatorLang.toLowerCase());
    },

     pageTitle: function() {
        return document.title;
    },

     incrementNumberOfVisit: function() {
         worldlineFunctions.incrementCookie("_numberOfVisit");
    },

     numberOfVisit: function() {
        return worldlineFunctions.getCookie("_numberOfVisit");
    },

     incrementNumberOfPageView: function() {
         worldlineFunctions.incrementCookie("_numberOfPageView");
    },

     numberOfPageView: function() {
        return worldlineFunctions.getCookie("_numberOfPageView");
    },

     setTimeOnTheWebSite: function() {
         worldlineFunctions.setCookie("_timeOnTheWebSite", Date.now());
    },

     timeOnTheWebSite: function() {
        return worldlineFunctions.getCookie("_timeOnTheWebSite");
    },

     setTimeOnCurrentPage: function() {
         worldlineFunctions.setCookie("_timeOnCurrentPage", Date.now());
    },

     timeOnCurrentPage: function() {
        return worldlineFunctions.getCookie("_timeOnCurrentPage");
    },

    setLastVisit: function() {
        worldlineFunctions.setCookie("_lastVisit", Date.now());
    },

    lastVisitDuration: function () {
        this.setLastVisit();
        return currentLastVisitValue ? currentLastVisitValue : Date.now();
    },

    format(language) {
        if (language.indexOf('-') > 0) { // format is xx-XX
            return language.split('-')[0];
        } else if (language.indexOf('_') > 0) {// format is xx_XX
            return language.split('_')[0];
        } else {
            return language;
        }
    }
};
