const minIEVersion = 10;
/**
 * Return the major version of the browser if the browser is Internet Explorer
 * @return {undefined|number} ex: 8 for IE8.X, 11 for IE11, ... undefined if it is not an IE browser
 */
function getIEVersion() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return undefined;
}

export function isBrowserSupported() {
    const ieVersion = getIEVersion();
    return ieVersion === undefined || ieVersion >= minIEVersion;
}
