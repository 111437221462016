const CHAT_INTEGRATION_PREFIX = 'CHAT_INTEGRATION:';
const timestamp = Date.now();
const minHeight = 470;
const minWidth = 700;

import { EngagementService } from './engagement-strategy';
import { isBrowserSupported } from './browser-support';
import { worldlineFunctions } from './chat-integration-function';

class ContactChat {
    /**
     * Instantiate a new ContactChat object.
     * @param ns WLContact shared object - "ns" stands for namespace
     * @param authStatus status of user authentication
     * @param minimized if the window needs to be open in the minimized way
     * @param userAlreadyConnected true if the user is already connected
     * @param chatAutomaticMessage the chat automatic message content
     * @param engagementStrategyUuid the uuid of the engage strategy
     * @param engagementStrategyName the name of the engage strategy
     */
    constructor(ns, authStatus, minimized, userAlreadyConnected, chatAutomaticMessage, engagementStrategyUuid, engagementStrategyName) {
        this.ns = ns;
        this.minimized = minimized;
        this.authStatus = authStatus;
        this.chatAutomaticMessage = chatAutomaticMessage;
        this.engagementStrategyUuid = engagementStrategyUuid;
        this.engagementStrategyName = engagementStrategyName;
        this.userAlreadyConnected = userAlreadyConnected;

        this.$ifrm = document.createElement('iframe');
        this.$ifrm.id = 'contact-chat';

        this.isMobileMode = (window.innerWidth < minWidth || window.innerHeight < minHeight) || this.isMobile();

        this.isMobileDevice = this.isMobile();

        if (this.isMobileDevice) {
            var meta = document.createElement('meta');
            meta.name = 'viewport';
            meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
            var head = document.getElementsByTagName('head')[0];
            head.appendChild(meta);
        }

        window.addEventListener('message', this.onMessage.bind(this));
        window.onresize = this.resize.bind(this);

        const uri = this.generateIframeUrl();
        this.buildChatDomainUrl(uri);

        this.$ifrm.setAttribute("src", uri.toString());
        document.body.appendChild(this.$ifrm);

    }

    isMobile() {
        var isMobileUserAgent = false;
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i) || navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)) {
            isMobileUserAgent = true;
        }
        return isMobileUserAgent;
    }

    resize() {
        if (((window.innerWidth < minWidth || window.innerHeight < minHeight) && !this.isMobileMode) || this.isMobileDevice) {
            this.isMobileMode = true;
            this.sendSizeChangedMessage();
        } else if (window.innerWidth > minWidth && window.innerHeight > minHeight && this.isMobileMode && !this.isMobileDevice) {
            this.isMobileMode = !this.isMobileMode;
            this.sendSizeChangedMessage();
        }
    }


    generateIframeUrl() {
        const uri = document.createElement('a');
        if (this.ns.chatServerUrl && this.ns.chatServerUrl.startsWith('/')) {
            uri.href = this.generateAbsoluteUrl(window.location)  + this.ns.chatServerUrl + "/";
        } else {
            if (this.ns.language) {
                uri.href = this.ns.chatServerUrl + '/#/' + this.ns.language + '/';
            } else {
                uri.href = this.ns.chatServerUrl + '/'
            }

        }
        return uri;
    }

    buildChatDomainUrl(uri) {
        if (uri.origin) {
            this.chatDomain = uri.origin;
        } else {
            this.chatDomain = this.generateAbsoluteUrl(uri);
        }
    }

    generateAbsoluteUrl(uri) {
        const protocolPrefix = uri.protocol + '//';
        const hostname = uri.hostname;
        const portSuffix = (!uri.port || uri.port === "80" || uri.port === "443") ? '' : (':' + uri.port);
        return protocolPrefix + hostname + portSuffix;
    }

    onMessage(msg) {
        if (msg.source !== this.$ifrm.contentWindow) {
            return;
        }
        switch (msg.data.type) {
            case 'CHAT_INTEGRATION:CHECK_PARENT':
                this.sendCheckParent();
                break;
            case 'CHAT_INTEGRATION:ASK_INFORMATION':
                this.sendSizeChangedMessage();
                this.setAutomaticMessage();
                this.sendStatsInfo();
                this.sendChatboxProperties();
                if (this.ns.chatFlowUri) {
                    this.sendFlowUri(this.ns.chatFlowUri);
                } else {
                    this.sendEngagementStrategyProperty();
                }
                this.manageOpeningStatus();
                this.sendUserAlreadyConnected();
                break;
            case 'CHAT_INTEGRATION:CHAT_CUSTOMER_ID':
                this.setChatCustomerId(msg.data.value);
                this.sendEngageStrategyMatchMessage();
                break;
            default:
                console.log('unhandled posted message : ', msg);
        }
    }

    sendCheckParent() {
        if (this.$ifrm) {
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'CHECK_PARENT'
            })), this.chatDomain);
        }
    }

    sendUserAlreadyConnected() {
        if (this.$ifrm) {
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'USER_ALREADY_CONNECTED',
                userAlreadyConnected: this.userAlreadyConnected,
            })), this.chatDomain);
        }
    }

    sendEngageStrategyMatchMessage() {
        let chatCustomerId = sessionStorage.getItem('CHAT_INTEGRATION:chatCustomerId');
        if (chatCustomerId && this.engagementStrategyUuid) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', this.chatDomain + '/chat/api/engagement-strategy/send-message/' + this.engagementStrategyUuid + '/' + chatCustomerId);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.onload = () => {};
            xhr.send(timestamp);
        }
    }

    setAutomaticMessage() {
        if (this.$ifrm) {
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'AUTOMATIC_MESSAGE',
                text: this.chatAutomaticMessage,
            })), this.chatDomain);
        }
    }

    show() {
        if (this.$ifrm) {
            this.$ifrm.style.display = 'block';
        }
    }

    hide() {
        if (this.$ifrm) {
            this.$ifrm.style.display = 'none';
        }
    }

    sendStatsInfo() {
        if (this.$ifrm) {
            let statMap = {};
            this.ns.cookiesStats.forEach((stat) => {
                if (worldlineFunctions[stat.trim()]) {
                    statMap[stat] = worldlineFunctions[stat.trim()]();
                }
            });
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'STATS_INFO',
                stats: statMap
            })), this.chatDomain);
        }
    }

    sendUserInfo(userFirstName, userLastName, customUserId) {
        if (this.$ifrm) {
            localStorage.setItem(CHAT_INTEGRATION_PREFIX + `user`, JSON.stringify(new User(userFirstName, userLastName, customUserId)));
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'USER_INFO',
                userFirstName: userFirstName,
                userLastName: userLastName,
                customUserId: customUserId
            })), window.origin);
        }
    }

    sendChatboxProperties() {
        if (this.$ifrm) {
            var allowPrint;
            this.isMobileDevice ? allowPrint = false : allowPrint = this.ns.allowPrintConversation;
            var allowSmiley;
            this.isMobileDevice ? allowSmiley = false : allowSmiley = this.ns.allowUseSmileys;
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'CHATBOX_PROPERTIES',
                allowPrintConversation: allowPrint || false,
                allowEmailConversation: this.ns.allowEmailConversation || false,
                allowUseSmileys: allowSmiley || false,
                showSystemMessagesOnPrintConversation: this.ns.showSystemMessagesOnPrintConversation || false,
                showSystemMessagesOnEmailConversation: this.ns.showSystemMessagesOnEmailConversation || false,
                headerBackgroundColor: this.ns.headerBackgroundColor,
                messageBackgroundColor: this.ns.messageBackgroundColor,
                titleColor: this.ns.titleColor,
                title: this.ns.title,
                templateForPrint: this.ns.templateForPrint || '',
                templateForEmail: this.ns.templateForEmail || '',
                isMobileDevice: this.isMobileDevice
            })), this.chatDomain);
        }
    }

    sendEngagementStrategyProperty() {
        if (this.$ifrm) {
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'ENGAGEMENT_STRATEGY_PROPERTIES',
                engagementStrategyUuid: this.engagementStrategyUuid || '',
                engagementStrategyName: this.engagementStrategyName || ''
            })), this.chatDomain);
        }
    }

    resetUserInfo() {
        localStorage.removeItem(CHAT_INTEGRATION_PREFIX + `user`);
    }

    sendToken(token) {
        if (this.$ifrm) {
            localStorage.setItem(CHAT_INTEGRATION_PREFIX + `token`, token);
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'TOKEN',
                token: token
            })), this.chatDomain);
        }
    }

    setChatCustomerId(chatCustomerId) {
        sessionStorage.setItem(CHAT_INTEGRATION_PREFIX + `chatCustomerId`, chatCustomerId);
    }

    resetToken() {
        localStorage.removeItem(CHAT_INTEGRATION_PREFIX + `token`);
    }

    sendFlowUri(flowUri) {
        if (this.$ifrm) {
            localStorage.setItem(CHAT_INTEGRATION_PREFIX + `flowUri`, flowUri);
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'FLOW_URI',
                flowUri: flowUri
            })), this.chatDomain);
        }
    }

    sendSizeChangedMessage() {
        if (this.$ifrm) {
            this.updateIframeSize();
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'SIZE_CHANGED',
                isMobileMode: this.isMobileMode
            })), this.chatDomain);
        }
    }

    updateIframeSize() {
        if (this.isMobileMode) {
            this.$ifrm.classList.remove('standardMode');
            this.$ifrm.classList.add('mobileMode');
        } else {
            this.$ifrm.classList.remove('mobileMode');
            this.$ifrm.classList.add('standardMode');
        }
    }

    manageOpeningStatus() {
        if (!this.minimized) {
            this.open();
        } else {
            this.close();
        }

        if (this.ns.showIframeAtOpening) {
            this.$ifrm.style.display = 'block';
        } else {
            this.$ifrm.style.display = 'none';
        }

    }

    open() {
        if (this.$ifrm) {
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'OPEN'
            })), this.chatDomain);
        }
    }

    end() {
        if (this.$ifrm) {
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'END'
            })), this.chatDomain);
        }
    }

    close() {
        if (this.$ifrm) {
            this.$ifrm.contentWindow.postMessage(JSON.parse(JSON.stringify({
                type: CHAT_INTEGRATION_PREFIX + 'CLOSE'
            })), this.chatDomain);
        }
    }
}

class User {
    constructor(firstName, lastName, customUserId) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.customUserId = customUserId;
    }

    toString() {
        return `${this.firstName} ${this.lastName}`;
    }
}

window.WLContact = window.WLContact || {};

(function(ns) {

    ns.isNullOrUndefined = (ref) => {
        return ref === null || ref === undefined;
    };

    ns.config = ns.config || {};
    ns.config.token = null;
    // ns.config.authServiceCheckFn = authServiceCheckFn;
    // if (!ns.config.authServiceCheckFn || typeof (ns.config.authServiceCheckFn) !== 'function') {
    //     ns.config.authServiceCheckFn = async() => ({ authenticationState: 'DEFAULT_SUCCESS' });
    // }

    window.addEventListener('wlloadchat', () => {
        if (isBrowserSupported()) {
            ns._engagementService = new EngagementService(ns, openChat);
        } else {
            console.log('The chat feature cannot be integrated because your browser is not supported.');
        }
    });

    function openChat(minimized= false, userAlreadyConnected = false, strategyUuid = undefined, message = undefined, authStatus = null, strategyName = undefined) {
        if (!ns.contactChat) {
            ns.contactChat = new ContactChat(ns, authStatus, minimized, userAlreadyConnected, message, strategyUuid, strategyName);
        }
    }
})(window.WLContact);
